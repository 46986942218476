.wrapper {
  animation-delay: 0.28s;
  margin: auto;
  text-align: center;
  display: inline-flex;
}

.child {
  width: calc(40px / 2);
  height: calc(40px / 2);
  flex-shrink: 0;

  border-radius: 100%;
  display: inline-block;
  animation: sk-three-bounce 1.2s ease-in-out 0s infinite both;
}

.bounce1 {
  animation-delay: -0.28s;
}

.bounce2 {
  animation-delay: calc(-0.28s / 2);
}

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

